import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { DocumentIcon } from "Svg/DocumentIcon";
export const EntityPayNotification = ({ id }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userInfo);
    const redirectToOrderPage = () => {
        if (user) {
            dispatch(removeNotificaionCategory("entityPay"));
            navigate(`/orders/${id}/`);
        }
    };
    return (React.createElement("div", { className: 'notifications__category__item' },
        React.createElement("div", { className: 'notifications__category__item__icon' },
            React.createElement(DocumentIcon, null)),
        React.createElement("div", { className: 'notifications__category__item__text' },
            React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u0430\u0448 \u0441\u0447\u0435\u0442 \u043F\u043E \u043E\u043F\u043B\u0430\u0442\u0435 \u0420\u0421 \u0432\u044B\u0441\u0442\u0430\u0432\u043B\u0435\u043D"),
            React.createElement("span", { className: "typography_captionTxt" + (user ? " customLink" : ""), onClick: redirectToOrderPage }, "\u0421\u043A\u0430\u0447\u0430\u0442\u044C \u0432 \u0441\u0432\u043E\u0435\u043C \u0437\u0430\u043A\u0430\u0437\u0435"))));
};
