import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    filtersData: null,
    categoriesNames: [],
    availableFilters: null,
    selectedFilters: null,
    subCategories: [],
    selectedFiltersCount: 0,
    pricesFilter: null,
};
export const catalogFiltersSlice = createSlice({
    name: "catalogFilters",
    initialState,
    reducers: {
        saveAllFilters(state, action) {
            state.filtersData = action.payload;
        },
        saveCategoriesNames(state, action) {
            state.categoriesNames = action.payload;
        },
        saveAvailableFilters(state, action) {
            state.availableFilters = action.payload;
        },
        saveSubCategories(state, action) {
            state.subCategories = action.payload;
        },
        changeSelectedFilters(state, action) {
            state.selectedFilters = action.payload;
        },
        changeSelectedFiltersCount(state, action) {
            state.selectedFiltersCount = action.payload;
        },
        savePricesFilter(state, action) {
            state.pricesFilter = action.payload;
        },
    },
});
export const { saveAllFilters, saveCategoriesNames, saveAvailableFilters, saveSubCategories, savePricesFilter, changeSelectedFilters, changeSelectedFiltersCount, } = catalogFiltersSlice.actions;
export default catalogFiltersSlice.reducer;
