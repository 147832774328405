import "./CatalogMenu.scss";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { siteHost } from "Static/variables";
import { ChevronIcon_16 } from "Svg/ChevronIcon_16";
export const CatalogMenu = ({ className, isOpen, leaveHandler, closeHandler, menuRef, isSSR, }) => {
    const { mainMenuCatalog } = useAppSelector((state) => state.app);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const mouseEnterHandler = (categoryId) => {
        setSelectedCategory(mainMenuCatalog.find((item) => item.id === categoryId) || null);
    };
    const [menuStyles, setLeftOffset] = useState({ sideOffset: 0, width: 0 });
    useEffect(() => {
        var _a, _b;
        !isOpen && setSelectedCategory(null);
        if (isOpen) {
            setLeftOffset({
                sideOffset: ((_b = (_a = menuRef.current) === null || _a === void 0 ? void 0 : _a.getClientRects()[0]) === null || _b === void 0 ? void 0 : _b.x) || 0,
                width: document.documentElement.clientWidth,
            });
        }
    }, [isOpen]);
    useEffect(() => {
        const resizeHandler = () => {
            var _a, _b;
            menuRef.current &&
                setLeftOffset({
                    sideOffset: ((_b = (_a = menuRef.current) === null || _a === void 0 ? void 0 : _a.getClientRects()[0]) === null || _b === void 0 ? void 0 : _b.x) || 0,
                    width: document.documentElement.clientWidth,
                });
        };
        addEventListener("resize", resizeHandler);
        resizeHandler();
        return () => {
            removeEventListener("resize", resizeHandler);
        };
    }, []);
    const nameDeclension = (name) => {
        const isMatch = !!name.match(/[а,ь]$/);
        return `${isMatch ? "Вся" : "Все"} ${name.toLocaleLowerCase()}`;
    };
    if (isSSR)
        return (React.createElement("div", null, mainMenuCatalog.map((category) => {
            var _a;
            const isRedirect = ((_a = category.children) === null || _a === void 0 ? void 0 : _a.length) === 0;
            if (isRedirect)
                return (React.createElement("a", { href: siteHost + category.url, key: category.id }, category.name));
            return (React.createElement("div", { key: category.id }, !!category.children.length &&
                category.children.map((subCategory) => (React.createElement("a", { href: siteHost + subCategory.url, key: subCategory.id }, subCategory.name)))));
        })));
    return (React.createElement("div", { id: 'catalogMenu', className: "catalogMenu" + (isOpen ? " catalogMenu_shown" : "") + (className ? ` ${className}` : ""), onMouseLeave: leaveHandler, style: { width: `${menuStyles.width}px`, paddingInline: `${menuStyles.sideOffset}px` } },
        React.createElement("div", { className: 'catalogMenu__main' }, mainMenuCatalog.map((category) => {
            var _a;
            const isRedirect = ((_a = category.children) === null || _a === void 0 ? void 0 : _a.length) === 0;
            return (React.createElement(NavLink, { className: 'catalogMenu__link catalogMenu__link_first typography_tec_16_menu', key: category.id, onMouseEnter: () => mouseEnterHandler(category.id), to: category.url, onClick: closeHandler },
                category.name,
                !isRedirect && React.createElement(ChevronIcon_16, null)));
        })),
        selectedCategory && !!selectedCategory.children.length && (React.createElement("div", { className: 'catalogMenu__secondary typography_tec_16_menu' },
            React.createElement("div", { className: 'catalogMenu__link typography_tec_16_menu', onMouseEnter: () => mouseEnterHandler(selectedCategory.id) },
                React.createElement(NavLink, { to: selectedCategory.url, onClick: closeHandler }, nameDeclension(selectedCategory.name)),
                React.createElement(ChevronIcon_16, null)),
            selectedCategory.children.map((category) => (React.createElement("div", { className: 'catalogMenu__link typography_tec_16_menu', key: category.id },
                React.createElement(NavLink, { to: category.url, onClick: closeHandler }, category.name))))))));
};
