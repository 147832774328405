import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addNotification, removeNotificaionCategory } from "Redux/slices/appSlice";
import { fetchApi } from "Utils/fetchApi";
import { getISO } from "Utils/getISO";
import localStorage from "Utils/localStorage";
export const useReviewNotification = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        const lastTimeStamp = localStorage.getItem("lastTimeStampOfReviewNotification");
        const currentTimeStamp = getISO("date");
        const isNoticeNotShownToday = (lastTimeStamp && new Date(currentTimeStamp) > new Date(lastTimeStamp)) || false;
        if (!lastTimeStamp || isNoticeNotShownToday) {
            dispatch(removeNotificaionCategory("review"));
            fetchApi({ method: "GET", url: "/review/product-notification" }).then(({ data }) => {
                if (data.product) {
                    localStorage.removeItem("lastTimeStampOfReviewNotification");
                    setTimeout(() => {
                        dispatch(addNotification({
                            category: "review",
                            id: Date.now().toString(),
                            content: data.product,
                        }));
                    }, 10000);
                }
                else {
                    dispatch(removeNotificaionCategory("review"));
                }
            });
        }
    }, []);
};
