import "./AuthModal.scss";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { reformatPhone } from "Utils/reformatPhone";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { addNotification, changeAuthModalVisibility, changeCity, changeDiscountIncrease, changeSnackbarData, } from "Redux/slices/appSlice";
import { addProductToCartOrFavourites } from "Redux/slices/cartSlice";
import { dataLayer, hostName } from "Static/variables";
import localStorage from "Utils/localStorage";
import { saveCookie } from "Utils/saveCookie";
import { fetchApi } from "Utils/fetchApi";
import { getCookie } from "Utils/getCookie";
import { ArrowIcon } from "Svg/ArrowIcon";
import { DocumentIcon } from "Svg/DocumentIcon";
import { CustomModal } from "Components/CustomModal/CustomModal";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CustomInput } from "Components/CustomInput/CustomInput";
import { PhoneCodesSheet } from "Components/PhoneCodesSheet/PhoneCodesSheet";
import { SubmitBtn } from "Components/SubmitBtn/SubmitBtn";
import { VerificationCode } from "Components/VerificationCode/VerificationCode";
import { CustomCheckbox } from "Components/CustomCheckbox/CustomCheckbox";
export const AuthModal = ({ onClose }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { city, isAuthModalShown } = useAppSelector((state) => state.app);
    const [isLoading, setIsLoading] = useState(false);
    const authAbortController = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [selectedPhoneCode, setSelectedPhoneCode] = useState("7");
    const concatPhone = useRef("");
    const [showLoginScreen, setShowLoginScreen] = useState(true);
    const [showPolicyScreen, setShowPolicyScreen] = useState(false);
    const [showCodeScreen, setShowCodeScreen] = useState(false);
    const [showSuccessRegister, setShowSuccessRegister] = useState(false);
    const authType = useRef("");
    const [authTitle, setAuthTitle] = useState("Войти или создать аккаунт");
    const [authSubTitle, setAuthSubTitle] = useState("");
    useEffect(() => {
        let titleText = "Войти или создать аккаунт";
        let subTitleText = "";
        if (showPolicyScreen) {
            titleText = "Для регистрации необходимо согласие";
            subTitleText = "на обработку персональных данных";
        }
        else if (showCodeScreen) {
            titleText = "Введите код";
            subTitleText = `Чтобы ${authType.current === "register" ? "зарегистрироваться" : "авторизоваться"}, подтвердите номер телефона, код выслан на номер:`;
        }
        else if (showSuccessRegister) {
            titleText = "Вы успешно зарегистрированы";
            subTitleText =
                "Теперь в личном кабинете добавьте персональные данные, контактную информацию, адреса доставки, любимые бренды";
        }
        setAuthTitle(titleText);
        setAuthSubTitle(subTitleText);
    }, [showPolicyScreen, showCodeScreen, showSuccessRegister]);
    const backToLoginScreen = () => {
        setCounter(59);
        setPhoneNumber("");
        setSelectedPhoneCode("7");
        concatPhone.current = "";
        authType.current = "";
        setShowLoginScreen(true);
        setShowPolicyScreen(false);
        setShowCodeScreen(false);
        setShowSuccessRegister(false);
    };
    const closeHandler = (params) => {
        dispatch(changeAuthModalVisibility(false));
        onClose && onClose();
        authAbortController.current && authAbortController.current.abort();
        setIsLoading(false);
        setTimeout(backToLoginScreen, 500);
        sessionStorage.removeItem("fromDiscountPromo");
        const authRedirectURL = sessionStorage.getItem("authRedirectURL");
        (params === null || params === void 0 ? void 0 : params.withRedirect) && authRedirectURL && navigate(authRedirectURL);
        sessionStorage.removeItem("authRedirectURL");
    };
    const authHandler = () => {
        if (phoneNumber.length > 0) {
            setIsLoading(true);
            concatPhone.current = selectedPhoneCode + phoneNumber;
            authAbortController.current = new AbortController();
            fetchApi({
                method: "POST",
                url: "/auth/request-code",
                body: { phone_number: concatPhone.current },
                signal: authAbortController.current.signal,
            })
                .then((res) => {
                setIsLoading(false);
                setShowLoginScreen(false);
                authType.current = res.data.type;
                res.data.type === "register" ? setShowPolicyScreen(true) : setShowCodeScreen(true);
            })
                .catch(() => setIsLoading(false))
                .finally(() => (authAbortController.current = null));
        }
        else {
            dispatch(changeSnackbarData({ message: "Некорректный номер", status: "error" }));
        }
    };
    const getCodeAndRegister = () => {
        if (showPolicyScreen) {
            setShowPolicyScreen(false);
            setShowCodeScreen(true);
        }
        fetchApi({ method: "POST", url: "/auth/register", body: { phone_number: concatPhone.current } });
    };
    const resendVerificationCode = () => {
        setCounter(59);
        fetchApi({
            method: "POST",
            url: `/auth/${authType.current === "register" ? "register" : "request-code"}`,
            body: { phone_number: concatPhone.current },
        });
    };
    const verificationCodeHandler = (code) => {
        setIsLoading(true);
        fetchApi({
            method: "POST",
            url: "/auth/confirm",
            body: {
                admitad_uid: getCookie("admitad_uid") || "",
                code,
                fromDiscountPromo: sessionStorage.getItem("fromDiscountPromo") === "true",
                phone_number: concatPhone.current,
                refLinkId: localStorage.getItem("refLinkId") || "",
            },
        })
            .then(({ data }) => {
            const currentUser = data.user;
            if (currentUser) {
                dispatch(saveAuthToken(data.token));
                dispatch(saveUserData(currentUser));
                dispatch(changeDiscountIncrease(currentUser.discountIncrease));
                currentUser.city.city_id !== city.city_id && dispatch(changeCity(currentUser.city));
                const localCart = localStorage.getItem("cart") || "[]";
                if (localCart !== JSON.stringify(currentUser.cart)) {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: currentUser.cart,
                        location: "cart",
                        needSyncWithDB: JSON.parse(localCart).length > 0,
                        isSoftAdding: true,
                    }));
                }
                const localFavourites = localStorage.getItem("favourites") || "[]";
                if (localFavourites !== JSON.stringify(currentUser.wishlist)) {
                    dispatch(addProductToCartOrFavourites({
                        newProducts: currentUser.wishlist,
                        location: "favourites",
                        needSyncWithDB: JSON.parse(localFavourites).length > 0,
                        isSoftAdding: true,
                        skipFavouritesIndicator: true,
                    }));
                }
                if (currentUser.role.id === "1" && "yaCounter10626637" in window) {
                    const YMInstance = window.yaCounter10626637;
                    YMInstance.setUserID(currentUser.id);
                    YMInstance.params({ customLvl_1: { customLvl_2: { UserID: currentUser.id } } });
                }
                if (authType.current === "login") {
                    closeHandler({ withRedirect: true });
                }
                else {
                    dataLayer.push({
                        event: "registration",
                    });
                    setShowCodeScreen(false);
                    setShowSuccessRegister(true);
                    dispatch(addNotification({
                        category: "discount",
                        id: currentUser.id,
                        content: "",
                    }));
                }
                saveCookie("isAuth", "true", { domain: `.${hostName}`, "max-age": 201600 });
                sendUtmLabels();
                localStorage.removeItem("refLinkId");
            }
            setIsLoading(false);
        })
            .catch(() => {
            setIsLoading(false);
            document.querySelector(".authModal").focus();
        });
    };
    const [counter, setCounter] = useState(59);
    useEffect(() => {
        let timer;
        if (+counter > 0 && showCodeScreen && isAuthModalShown) {
            timer = setTimeout(() => {
                if (+counter > 10) {
                    setCounter((c) => +c - 1);
                }
                else {
                    setCounter((c) => "0" + (+c - 1));
                }
            }, 1000);
        }
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter, showCodeScreen, isAuthModalShown]);
    const finishRegistration = () => {
        const checkbox = document.getElementById("authModal__subscribeCheckbox");
        fetchApi({
            method: "POST",
            url: "/user/save-subscription",
            body: {
                subscribe: checkbox.checked,
            },
        }).then(({ data }) => dispatch(saveUserData(data)));
        closeHandler({ withRedirect: true });
    };
    const sendUtmLabels = () => {
        const utmLabels = localStorage.getItem("utmLabels");
        if (utmLabels) {
            fetchApi({
                method: "POST",
                url: "/user/save-utm",
                body: { labels: JSON.parse(utmLabels) },
                hideErrorSnackbar: true,
            });
            localStorage.removeItem("utmLabels");
        }
    };
    useEffect(() => {
        addEventListener("showAuth", () => dispatch(changeAuthModalVisibility(true)));
    }, []);
    return (React.createElement(CustomModal, { className: 'authModal', open: isAuthModalShown, maskClosable: false, onClose: closeHandler },
        React.createElement("div", { className: 'customModal__content__header' },
            !showLoginScreen && !showSuccessRegister && (React.createElement(ArrowIcon, { className: 'authModal__header__controls__arrowIcon', onClick: backToLoginScreen })),
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', isDefaultClosePosition: true, onClick: closeHandler }),
            React.createElement("span", { className: 'typography_tec_title' }, authTitle),
            !showLoginScreen && (React.createElement("span", { className: 'customModal__content__header__subTitle typography_bodyTxt' }, authSubTitle))),
        React.createElement("div", { className: 'customModal__content__body' },
            showLoginScreen && (React.createElement("div", { className: 'authModal__loginScreen__form' },
                React.createElement(CustomInput, { autoFocus: true, disabled: isLoading, id: 'authModal__userPhone', inputValue: phoneNumber, label: '\u041A\u043E\u043D\u0442\u0430\u043A\u0442\u043D\u044B\u0439 \u0442\u0435\u043B\u0435\u0444\u043E\u043D', setInputValue: setPhoneNumber, type: 'phone', onKeyDownHandler: (e) => e.key === "Enter" && authHandler(), prefix: React.createElement(PhoneCodesSheet, { setSelectedPhoneCode: setSelectedPhoneCode, inputId: 'authModal__userPhone', selectedPhoneCode: selectedPhoneCode }) }),
                React.createElement(SubmitBtn, { controlLocation: 'modal', disabled: isLoading || (!isLoading && phoneNumber.length < 5), isLoading: isLoading, loadingText: '\u041E\u0442\u043F\u0440\u0430\u0432\u043A\u0430 \u043A\u043E\u0434\u0430', onClick: authHandler, text: '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C' }))),
            showPolicyScreen && (React.createElement("div", { className: 'authModal__policyScreen' },
                React.createElement("div", { className: 'authModal__policyScreen__content' },
                    React.createElement(DocumentIcon, { className: 'authModal__policyScreen__content__icon' }),
                    React.createElement(NavLink, { className: 'authModal__policyScreen__content__info', to: '/service/consentToProcessing/', target: '_blank', rel: 'noopener noreferrer' },
                        React.createElement("span", { className: 'typography_h3' }, "\u041F\u043E\u043B\u0438\u0442\u0438\u043A\u0430 \u043A\u043E\u043D\u0444\u0438\u0434\u0435\u043D\u0446\u0438\u0430\u043B\u044C\u043D\u043E\u0441\u0442\u0438"),
                        React.createElement("span", { className: 'typography_captionTxt' }, "\u043E\u0431 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D\u0438\u0438 \u043F\u0435\u0440\u0441\u043E\u043D\u0430\u043B\u044C\u043D\u044B\u0445 \u0434\u0430\u043D\u043D\u044B\u0445 \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u2014 \u0444\u0438\u0437\u0438\u0447\u0435\u0441\u043A\u0438\u0445 \u043B\u0438\u0446"))),
                React.createElement(SubmitBtn, { onClick: getCodeAndRegister, controlLocation: 'modal', text: '\u041F\u0440\u043E\u0447\u0438\u0442\u0430\u043B, \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u044E\u0441\u044C' }))),
            showCodeScreen && (React.createElement("div", { className: 'authModal__codeScreen' },
                React.createElement("span", { className: 'authModal__codeScreen__phoneNumber typography_bodyTxt' },
                    "+",
                    selectedPhoneCode,
                    " ",
                    reformatPhone(phoneNumber)),
                React.createElement(VerificationCode, { codeHandler: verificationCodeHandler, "is\u0421odeUnderVerifying": isLoading }),
                React.createElement(SubmitBtn, { controlLocation: 'modal', disabled: isLoading || (!isLoading && counter !== "00"), isLoading: isLoading, loadingText: '\u041F\u0440\u043E\u0432\u0435\u0440\u044F\u0435\u0442\u0441\u044F', onClick: resendVerificationCode, text: counter !== "00" ? `Повтор отправки через 00:${counter}` : "Повторить отправку" }))),
            showSuccessRegister && (React.createElement("div", { className: 'authModal__successRegister' },
                React.createElement(CustomCheckbox, { className: 'authModal__successRegister__agreements_sale', id: 'authModal__subscribeCheckbox', checked: true, darkMode: true, labelText: React.createElement("span", { className: 'typography_captionTxt' }, "\u041D\u0438\u043A\u0430\u043A\u043E\u0433\u043E \u0441\u043F\u0430\u043C\u0430, \u0442\u043E\u043B\u044C\u043A\u043E \u043B\u0443\u0447\u0448\u0438\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F \u0434\u043B\u044F \u0432\u0430\u0441 \u043E \u043F\u043E\u0442\u0440\u044F\u0441\u0430\u044E\u0449\u0438\u0445 \u0441\u043A\u0438\u0434\u043A\u0430\u0445 \u0438 \u0441\u0430\u043C\u044B\u0445 \u043A\u0440\u0443\u0442\u044B\u0445 \u043D\u043E\u0432\u0438\u043D\u043A\u0430\u0445"), size: 'small' }),
                React.createElement(SubmitBtn, { onClick: finishRegistration, controlLocation: 'modal', text: '\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C' }))))));
};
