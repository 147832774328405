import "Style/classes/carouselControls-arrow.scss";
import "./GoodsCarousel.scss";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, FreeMode, Navigation } from "swiper/modules";
import { useAppSelector } from "Hooks/useAppSelector";
import { reformatPrice } from "Utils/reformatPrice";
import { GoodsCarouselFallback } from "./components/GoodsCarouselFallback";
import { CarouselNavArrow } from "Components/CarouselNavArrow/CarouselNavArrow";
import { FavouritesHeart } from "Components/FavouritesHeart/FavouritesHeart";
import { Label } from "Components/Label/Label";
import { ArrowIcon } from "Svg/ArrowIcon";
import { productClickGTM } from "Utils/analyticEvents";
export const GoodsCarousel = ({ catalogLink = null, category, categoryOfGoodsData, className, gtmList, intersectionObserverClassName, isLoading = false, }) => {
    const location = useLocation();
    const { deviceInfo, breakpointMatches } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const [swiperRef, setSwiperRef] = useState(null);
    const [numberOfSlides, setNumberOfSlides] = useState("auto");
    const [paddingOfSlides, setPaddingOfSlides] = useState(16);
    const [isSlidesSticky, setIsSlidesSticky] = useState(true);
    useLayoutEffect(() => {
        breakpointMatches[1440] ? setNumberOfSlides(3) : setNumberOfSlides(4);
        breakpointMatches[960] && setNumberOfSlides("auto");
        if (breakpointMatches[600]) {
            setPaddingOfSlides(8);
            setIsSlidesSticky(false);
        }
        else {
            setPaddingOfSlides(16);
            setIsSlidesSticky(true);
        }
    }, [breakpointMatches]);
    useEffect(() => {
        swiperRef && swiperRef.slideReset(0);
    }, [numberOfSlides]);
    const getAltDescription = (product) => {
        let result = `Модель: ${product.model}; Бренд: ${product.brand.name}; `;
        product.altData &&
            Object.keys(product.altData).forEach((name) => {
                if (name === "materials") {
                    const value = product.altData[name].length ? product.altData[name].join(", ") : "Не указано";
                    result += `Материал: ${value}; `;
                }
                else if (name === "color") {
                    result += `Цвет: ${product.altData[name] || "Не указано"}; `;
                }
                else if (name === "country") {
                    result += `Страна производства: ${product.altData[name] || "Не указано"}`;
                }
            });
        return result;
    };
    const productPreviewClickHandler = (products) => {
        if ((!user || (user && user.role.id === "1")) && gtmList) {
            productClickGTM({
                list: gtmList,
                productsForSend: products,
                fullProductsList: categoryOfGoodsData,
            });
        }
    };
    return !isLoading && !!(categoryOfGoodsData === null || categoryOfGoodsData === void 0 ? void 0 : categoryOfGoodsData.length) ? (React.createElement("div", { className: `goodsCarousel goodsCarousel_${category}` +
            (intersectionObserverClassName ? ` ${intersectionObserverClassName}` : "") +
            (className ? ` ${className}` : "") },
        React.createElement(CarouselNavArrow, { className: `goodsCarousel__prevArrow goodsCarousel__prevArrow_${category}`, type: 'prev' }),
        React.createElement(Swiper, { className: 'goodsCarousel__track', onSwiper: setSwiperRef, spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides, keyboard: true, freeMode: {
                enabled: true,
                sticky: isSlidesSticky,
                momentumBounce: false,
            }, speed: deviceInfo.deviceType === "desktop" ? 300 : 100, threshold: deviceInfo.deviceType === "desktop" ? 0 : 3, navigation: {
                prevEl: `.goodsCarousel__prevArrow_${category}`,
                nextEl: `.goodsCarousel__nextArrow_${category}`,
            }, modules: [Keyboard, FreeMode, Navigation] },
            categoryOfGoodsData.map((product, idx) => (React.createElement(SwiperSlide, { key: product.id, onClick: () => productPreviewClickHandler([product]) },
                React.createElement("div", { className: 'goodsCarousel__track__slide' },
                    React.createElement("div", { className: 'goodsCarousel__track__slide__imgWrapper' },
                        React.createElement(FavouritesHeart, { className: 'goodsCarousel__track__slide__imgWrapper__favouritesIcon', category: category, product: product }),
                        React.createElement(NavLink, { "aria-label": `preview of - ${product.model}`, itemScope: true, itemType: 'https://schema.org/ImageObject', to: {
                                pathname: `/products/${product.url}/`,
                                search: category === "mindbox" ? `?from=mb_${location.pathname.split("/")[1]}` : "",
                            }, target: breakpointMatches[960] || deviceInfo.deviceType === "mobile" ? "_self" : "_blank", rel: 'noopener noreferrer', title: `${product.model}${product.model_full ? " " + product.model_full : ""}`, onError: (e) => {
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                e.currentTarget.style.borderRadius = "12px";
                            } },
                            React.createElement("img", { className: 'skeletonLoading', itemProp: 'contentUrl', src: product.image, alt: getAltDescription(product), loading: idx > 3 ? (deviceInfo.browserName !== "Safari" ? "lazy" : "eager") : undefined, onLoad: (e) => e.currentTarget.classList.remove("skeletonLoading"), onError: (e) => {
                                    e.currentTarget.classList.remove("skeletonLoading");
                                    e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                } })),
                        product.promoLabel && (React.createElement(Label, { className: 'goodsCarousel__track__slide__imgWrapper__label', labelData: product.promoLabel }))),
                    React.createElement("div", { className: 'goodsCarousel__track__slide__productInfo' },
                        React.createElement("img", { className: 'skeletonLoading', src: product.brand.image, alt: `${product.brand.name} brand logo`, loading: idx > 3 ? (deviceInfo.browserName !== "Safari" ? "lazy" : "eager") : undefined, onLoad: (e) => e.currentTarget.classList.remove("skeletonLoading"), onError: (e) => {
                                e.currentTarget.classList.remove("skeletonLoading");
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                            } }),
                        React.createElement("span", { className: 'goodsCarousel__track__slide__productInfo__name typography_tec_16_default' }, product.model),
                        React.createElement("div", { className: 'goodsCarousel__track__slide__productInfo__price', itemScope: true, itemProp: 'offers', itemType: 'http://schema.org/Offer' },
                            React.createElement("p", { className: "goodsCarousel__track__slide__productInfo__price__actual" +
                                    (product.actual_price !== product.price
                                        ? " goodsCarousel__track__slide__productInfo__price__discount"
                                        : "") }, reformatPrice(product.actual_price)),
                            product.actual_price !== product.price && (React.createElement("p", { className: 'goodsCarousel__track__slide__productInfo__price__oldPrice typography_tec_16_default' }, reformatPrice(product.price))))))))),
            catalogLink && (React.createElement(SwiperSlide, null,
                React.createElement("div", { className: 'goodsCarousel__track__slide' },
                    React.createElement("div", { className: 'goodsCarousel__track__slide__imgWrapper' },
                        React.createElement(NavLink, { rel: 'noopener noreferrer', target: '_blank', to: catalogLink.url, onError: (e) => {
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                e.currentTarget.style.borderRadius = "12px";
                            } },
                            React.createElement("img", { className: 'skeletonLoading', src: catalogLink.image, alt: `Link to - ${category}`, loading: deviceInfo.browserName !== "Safari" ? "lazy" : "eager", onLoad: (e) => e.currentTarget.classList.remove("skeletonLoading"), onError: (e) => {
                                    e.currentTarget.classList.remove("skeletonLoading");
                                    e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                } }))),
                    React.createElement("p", { className: 'goodsCarousel__track__slide__promoTxt typography_bodyTxt' }, "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432\u0441\u0435 \u0442\u043E\u0432\u0430\u0440\u044B \u0434\u0430\u043D\u043D\u043E\u0439 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
                    React.createElement(NavLink, { className: 'goodsCarousel__track__slide__promoBtn customBtn customBtn_outline', rel: 'noopener noreferrer', target: '_blank', to: catalogLink.url },
                        "\u0412\u0441\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F",
                        React.createElement(ArrowIcon, null)))))),
        React.createElement(CarouselNavArrow, { className: `goodsCarousel__nextArrow goodsCarousel__nextArrow_${category}`, type: 'next' }))) : isLoading ? (React.createElement(GoodsCarouselFallback, { category: category, intersectionObserverClassName: intersectionObserverClassName, withTitle: false })) : null;
};
