import { configureStore } from "@reduxjs/toolkit";
import appSlice from "Redux/slices/appSlice";
import cartSlice from "Redux/slices/cartSlice";
import postsSlice from "Redux/slices/postsSlice";
import userInfoSlice from "Redux/slices/userInfoSlice";
import catalogFiltersSlice from "Redux/slices/catalogFiltersSlice";
import returnSlice from "Redux/slices/returnSlice";
import { hostName } from "Static/variables";
import { isBrowser } from "Utils/isBrowser";
const store = configureStore({
    reducer: {
        app: appSlice,
        cart: cartSlice,
        posts: postsSlice,
        userInfo: userInfoSlice,
        catalogFilters: catalogFiltersSlice,
        return: returnSlice,
    },
    devTools: isBrowser() && window.location.host !== hostName,
});
export default store;
