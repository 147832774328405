import "./ProductLeaveReviewDrawer.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { useShow } from "Hooks/useShow";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { fetchApi } from "Utils/fetchApi";
import { getReviewUserName } from "Utils/getReviewUserName";
import { AlertMonochromeIcon } from "Svg/AlertMonochromeIcon";
import { CameraIcon } from "Svg/CameraIcon";
import { ReviewFillStarIcon } from "Svg/ReviewFillStarIcon";
import DonePNG from "Media/img/NPS/done.png";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { CustomTextArea } from "Components/CustomTextarea/CustomTextArea";
import { CustomToggleSwitch } from "Components/CustomToggleSwitch/CustomToggleSwitch";
import { Loader } from "Components/Loader/Loader";
import { bodyErrorMessage, maxBodyLength } from "./constants";
export const ProductLeaveReviewDrawer = ({ brandName, leaveReviewOpen, mainPhoto, model_full, model, onLeaveReviewClose, onReviewLeaveFinish, productId, rating, sizes, }) => {
    const dispatch = useAppDispatch();
    const { breakpointMatches } = useAppSelector((state) => state.app);
    const { user } = useAppSelector((state) => state.userInfo);
    const { open: successReviewOpen, onOpen: onSuccessReviewOpen, onClose: onSuccessReviewClose } = useShow();
    const [reviewFormData, setReviewFormData] = useState({
        rating: 0,
        hide_my_data: false,
        body: "",
        dimensions: null,
    });
    const isBodyTooLong = reviewFormData.body.length > maxBodyLength;
    const [preloadedPhotos, setPreloadedPhotos] = useState(null);
    const [isSendingReview, setIsSendingReview] = useState(false);
    useEffect(() => {
        rating && onChangeRating(rating);
    }, [rating]);
    const onSendReview = async () => {
        setIsSendingReview(true);
        let photoIds = [];
        if (preloadedPhotos && [...preloadedPhotos].length) {
            const promises = [];
            [...preloadedPhotos].forEach((photo) => {
                const preloadedPhotosBody = new FormData();
                preloadedPhotosBody.append("photo", photo);
                const promise = fetchApi({
                    url: "/review/upload-photo",
                    method: "POST",
                    body: preloadedPhotosBody,
                });
                promises.push(promise);
            });
            const results = await Promise.allSettled(promises);
            photoIds = results.map((result) => {
                if ("value" in result) {
                    return result.value.data.photo.id;
                }
            });
        }
        const body = {
            ...reviewFormData,
            product_id: productId,
            photo_ids: photoIds,
        };
        try {
            const response = await fetchApi({ method: "POST", url: "/review/send", body });
            const data = JSON.parse(response.data);
            if ("errors" in data) {
                Object.entries(data.errors).forEach(([key, item]) => {
                    var _a;
                    if (Array.isArray(item) && item.length) {
                        const [firstError] = item;
                        throw new Error(((_a = item[0]) === null || _a === void 0 ? void 0 : _a.message) ? `${firstError.message}` : "Ошибка");
                    }
                });
            }
            onLeaveReviewClose(true);
            onSuccessReviewOpen();
        }
        catch (err) {
            if (err instanceof Error) {
                dispatch(changeSnackbarData({ message: err.message, status: "error" }));
            }
        }
        finally {
            setIsSendingReview(false);
        }
    };
    const onChangeChip = (chip) => {
        setReviewFormData((prev) => ({ ...prev, dimensions: prev.dimensions === chip ? null : chip }));
    };
    const [hoveredStars, setHoveredStars] = useState(0);
    const onChangeRating = (index) => setReviewFormData((prev) => ({ ...prev, rating: index }));
    const onTriggerFileInput = () => {
        const fileInput = document.getElementById("upload-photos");
        if (fileInput) {
            fileInput.click();
        }
    };
    const onUploadFile = (e) => {
        const files = e.target.files;
        if (files && [...files].length > 5) {
            dispatch(changeSnackbarData({ message: "Максимальное количество файлов 5", status: "error" }));
            return;
        }
        setPreloadedPhotos(files);
    };
    const closeSuccessReview = () => {
        onSuccessReviewClose();
        onReviewLeaveFinish && onReviewLeaveFinish();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustomDrawer, { contentWrapperClassName: 'productLeaveReviewDrawer-wrapper', className: 'adaptivePopup__drawer', open: leaveReviewOpen, onClose: () => onLeaveReviewClose(false), placement: !breakpointMatches[768] ? "right" : "bottom", destroyOnClose: true, title: React.createElement("div", { className: 'productLeaveReviewDrawer__title productLook__drawer__title' },
                React.createElement("span", { className: 'customServiceDrawer_side__header__title typography_tec_13_semiBold' }, "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u0437\u044B\u0432"),
                React.createElement(CloseIcon, { className: 'customServiceDrawer_side__header__closeIcon', onClick: () => onLeaveReviewClose(false) })) },
            React.createElement("div", { className: 'productLeaveReviewDrawer__content' },
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__info' },
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__info__description' },
                        React.createElement("img", { className: 'productLeaveReviewDrawer__content__info__description__image', src: typeof mainPhoto === "object" ? mainPhoto.normal : mainPhoto, alt: `${model}${model_full ? " " + model_full : ""}` }),
                        React.createElement("div", { className: 'productLeaveReviewDrawer__content__info__description__article' },
                            React.createElement("h3", { className: 'typography_captionTxt' }, brandName),
                            React.createElement("p", { className: 'productLeaveReviewDrawer__content__info__description__article__model typography_captionTxt' },
                                "\u041C\u043E\u0434\u0435\u043B\u044C: ",
                                `${model}${model_full ? " " + model_full : ""}`),
                            React.createElement("p", { className: 'productLeaveReviewDrawer__content__info__description__article__sizes typography_captionTxt' },
                                React.createElement("span", { className: 'productLeaveReviewDrawer__content__info__description__article__sizes__size' }, "\u0420\u0430\u0437\u043C\u0435\u0440:"),
                                " ",
                                sizes.map((size) => size.size).join(", ")))),
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__info__alert' },
                        React.createElement(AlertMonochromeIcon, null),
                        React.createElement("p", { className: 'productLeaveReviewDrawer__content__info__alert__caption typography_captionTxt' }, "\u0415\u0441\u043B\u0438 \u043A\u0430\u0447\u0435\u0441\u0442\u0432\u043E \u043F\u043E\u043A\u0443\u043F\u043A\u0438 \u0432\u0430\u0441 \u0443\u0441\u0442\u0440\u043E\u0438\u043B\u043E, \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u043E\u0446\u0435\u043D\u043A\u0443 \u0442\u043E\u0432\u0430\u0440\u0430. \u041E\u0441\u0442\u0430\u043B\u044C\u043D\u044B\u0435 \u043F\u043E\u043B\u044F - \u043D\u0435 \u043E\u0431\u044F\u0437\u0430\u0442\u0435\u043B\u044C\u043D\u044B"))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__rating' },
                    React.createElement("h2", { className: 'typography_tec_16_medium' }, "\u041E\u0446\u0435\u043D\u0438\u0442\u0435 \u043F\u043E\u043A\u0443\u043F\u043A\u0443"),
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__rating__stars', onMouseLeave: () => setHoveredStars(0) }, Array.from({ length: 5 }).map((_, index) => {
                        const getFill = () => {
                            if (reviewFormData.rating >= index + 1) {
                                return "var(--blue-400)";
                            }
                            if (hoveredStars > index) {
                                return "var(--blue-200)";
                            }
                            return undefined;
                        };
                        return (React.createElement(ReviewFillStarIcon, { key: index, className: 'productLeaveReviewDrawer__content__rating__stars__star', fill: getFill(), onClick: () => onChangeRating(index + 1), onMouseOver: () => setHoveredStars(index + 1) }));
                    }))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__share' },
                    React.createElement("h2", { className: 'typography_tec_16_medium' }, "\u041F\u043E\u0434\u0435\u043B\u0438\u0442\u0435\u0441\u044C \u0432\u043F\u0435\u0447\u0430\u0442\u043B\u0435\u043D\u0438\u044F\u043C\u0438 \u043E \u0442\u043E\u0432\u0430\u0440\u0435"),
                    React.createElement(CustomTextArea, { className: "productLeaveReviewDrawer__content__share__textArea typography_tec_14_body" +
                            (isBodyTooLong ? " productLeaveReviewDrawer__content__share__textArea_error" : ""), id: 'share-textarea', placeholder: '\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439', value: reviewFormData.body, onChange: (e) => setReviewFormData((prev) => ({ ...prev, body: e.target.value })) }),
                    React.createElement("p", { className: 'productLeaveReviewDrawer__content__share__textArea__error typography_tec_13_medium' }, isBodyTooLong && (React.createElement(React.Fragment, null,
                        React.createElement("span", null, bodyErrorMessage),
                        React.createElement("div", null,
                            reviewFormData.body.length,
                            "/",
                            maxBodyLength))))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__conformity' },
                    React.createElement("h2", { className: 'typography_tec_16_medium' }, "\u0421\u043E\u043E\u0442\u0432\u0435\u0442\u0441\u0442\u0432\u0438\u0435 \u0440\u0430\u0437\u043C\u0435\u0440\u0430"),
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__conformity__chips' }, ["В размер", "Большемерит", "Маломерит"].map((chip) => {
                        return (React.createElement("div", { key: chip, className: "productLeaveReviewDrawer__content__conformity__chip" +
                                (chip === reviewFormData.dimensions
                                    ? " productLeaveReviewDrawer__content__conformity__chip_active"
                                    : ""), onClick: () => onChangeChip(chip) },
                            React.createElement("span", { className: 'typography_tec_14_medium' }, chip)));
                    }))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__uploadPhotos' },
                    React.createElement("h2", { className: 'typography_tec_16_medium' },
                        "\u0417\u0430\u0433\u0440\u0443\u0436\u0435\u043D\u043D\u044B\u0435 \u0444\u043E\u0442\u043E\u0433\u0440\u0430\u0444\u0438\u0438",
                        " ",
                        React.createElement("span", { className: 'productLeaveReviewDrawer__content__uploadPhotos__amount' },
                            preloadedPhotos ? [...preloadedPhotos].length : 0,
                            "/5")),
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__uploadPhotos__input' },
                        React.createElement("div", { className: 'productLeaveReviewDrawer__content__uploadPhotos__input__uploadIcon', onClick: onTriggerFileInput },
                            React.createElement(CameraIcon, null),
                            React.createElement("input", { id: 'upload-photos', type: 'file', accept: 'image/png, image/jpeg', multiple: true, hidden: true, onChange: onUploadFile })),
                        React.createElement("p", { className: 'productLeaveReviewDrawer__content__uploadPhotos__input__label typography_captionTxt' },
                            "\u0434\u043E\u00A05\u00A0\u0438\u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0439 \u0432\u00A0\u0444\u043E\u0440\u043C\u0430\u0442\u0435 ",
                            React.createElement("br", null),
                            " PNG, JPEG."))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__author' },
                    React.createElement("p", { className: 'productLeaveReviewDrawer__content__author__caption' }, "\u0412\u044B \u043E\u0441\u0442\u0430\u0432\u043B\u044F\u0435\u0442\u0435 \u043E\u0442\u0437\u044B\u0432 \u043A\u0430\u043A:"),
                    React.createElement("h2", { className: 'productLeaveReviewDrawer__content__author__name typography_h3' }, getReviewUserName({
                        firstName: user === null || user === void 0 ? void 0 : user.first_name,
                        hideMyData: reviewFormData.hide_my_data,
                        lastName: user === null || user === void 0 ? void 0 : user.last_name,
                    })),
                    React.createElement("div", { className: 'productLeaveReviewDrawer__content__author__switchWrapper' },
                        React.createElement("p", { className: 'productLeaveReviewDrawer__content__author__switchLabel typography_captionTxt' }, "\u0421\u043A\u0440\u044B\u0442\u044C \u043C\u043E\u0438 \u0434\u0430\u043D\u043D\u044B\u0435 \u0432 \u043E\u0442\u0437\u044B\u0432\u0435"),
                        React.createElement("div", { className: 'productLeaveReviewDrawer__content__author__switch' },
                            React.createElement(CustomToggleSwitch, { isChecked: reviewFormData.hide_my_data, onChangeHandler: (checked) => setReviewFormData((prev) => ({ ...prev, hide_my_data: checked })) })))),
                React.createElement("div", { className: 'productLeaveReviewDrawer__content__submit' },
                    React.createElement("button", { disabled: !productId || !reviewFormData.rating || isBodyTooLong, className: 'productLeaveReviewDrawer__content__submit__btn customBtn customBtn_contained', onClick: onSendReview }, isSendingReview ? React.createElement(Loader, { height: 24 }) : "Отправить отзыв")))),
        React.createElement(CustomDrawer, { contentWrapperClassName: 'productLeaveReviewSuccessDrawer-wrapper', className: 'adaptivePopup__drawer', open: successReviewOpen, onClose: closeSuccessReview, destroyOnClose: true, title: React.createElement("div", { className: 'productLeaveReviewDrawer__title productLook__drawer__title' },
                React.createElement("span", { className: 'customServiceDrawer_side__header__title typography_tec_13_semiBold' }, "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u0437\u044B\u0432"),
                React.createElement(CloseIcon, { className: 'customServiceDrawer_side__header__closeIcon', onClick: closeSuccessReview })) },
            React.createElement("div", { className: 'productLeaveReviewSuccessDrawer__content' },
                React.createElement("img", { className: 'productLeaveReviewSuccessDrawer__content__image', src: DonePNG, alt: 'done' }),
                React.createElement("h3", { className: 'productLeaveReviewSuccessDrawer__content__heading typography_h3' }, "\u0421\u043F\u0430\u0441\u0438\u0431\u043E \u0437\u0430 \u043E\u0442\u0437\u044B\u0432"),
                React.createElement("p", { className: 'productLeaveReviewSuccessDrawer__content__caption typography_h4' },
                    "\u041F\u043E\u0441\u043B\u0435\u00A0\u043C\u043E\u0434\u0435\u0440\u0430\u0446\u0438\u0438 \u043E\u043D\u00A0\u043F\u043E\u044F\u0432\u0438\u0442\u0441\u044F ",
                    React.createElement("br", null),
                    " \u0432\u00A0\u043A\u0430\u0440\u0442\u043E\u0447\u043A\u0435 \u0442\u043E\u0432\u0430\u0440\u0430")))));
};
