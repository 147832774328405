import "./CustomTextArea.scss";
import React from "react";
export const CustomTextArea = ({ className, disabled, id, label, onChange, placeholder, postscript, value, size = "normal", }) => {
    return (React.createElement("div", { className: "customTextArea-wpaper" +
            (className ? ` ${className}` : "") +
            (disabled ? " customTextArea-wpaper_disabled" : "") },
        label && (React.createElement("label", { className: 'typography_tec_13_medium customTextArea__label', htmlFor: id }, label)),
        React.createElement("div", { className: 'customTextArea-subWpaper' },
            React.createElement("textarea", { className: `customTextArea customTextArea_${size} typography_tec_16_default customLightScrollbar`, id: id, value: value, placeholder: placeholder, disabled: disabled, onChange: onChange })),
        postscript && React.createElement("span", { className: 'customTextArea__postMessage typography_tec_13_medium' }, postscript)));
};
