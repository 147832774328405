import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { declOfNum } from "Utils/declOfNum";
import { DeliveryBoxIcon } from "Svg/DeliveryBoxIcon";
export const OrderNotification = ({ id, content }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userInfo);
    const redirectToOrderPage = () => {
        if (user) {
            dispatch(removeNotificaionCategory("orders"));
            navigate(`/orders/${id}/`);
        }
    };
    return (React.createElement("div", { className: 'notifications__category__item' },
        React.createElement("div", { className: 'notifications__category__item__icon' },
            React.createElement(DeliveryBoxIcon, null)),
        React.createElement("div", { className: 'notifications__category__item__text' },
            React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0412\u0430\u0448 \u0437\u0430\u043A\u0430\u0437 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D"),
            React.createElement("span", { className: "typography_captionTxt" + (user ? " customLink" : ""), onClick: redirectToOrderPage }, `В заказе ${content} ${declOfNum(content, ["товар", "товара", "товаров"])}`))));
};
