import { useAppDispatch } from "Hooks/useAppDispatch";
import { saveAuthToken, saveUserData } from "Redux/slices/userInfoSlice";
import { removeProductsFromLocalCartOrFavourites } from "Redux/slices/cartSlice";
import { rewriteNotifications, rewriteUnvisitedOrders } from "Redux/slices/appSlice";
import { deleteCookie } from "Utils/deleteCookie";
import { hostName } from "Static/variables";
export const useLogout = () => {
    const dispatch = useAppDispatch();
    return () => {
        dispatch(saveUserData(null));
        dispatch(saveAuthToken(""));
        dispatch(removeProductsFromLocalCartOrFavourites(["cart", "favourites"]));
        dispatch(rewriteNotifications({ data: [], withLocalSave: true }));
        dispatch(rewriteUnvisitedOrders(0));
        deleteCookie("isAuth", { domain: `.${hostName}` });
        window.scrollTo(0, 0);
    };
};
