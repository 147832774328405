import React from "react";
import { InfoIcon } from "Svg/InfoIcon";
export const CertificatesNotification = () => {
    return (React.createElement("div", { className: 'notifications__category__item' },
        React.createElement("div", { className: 'notifications__category__item__icon' },
            React.createElement(InfoIcon, null)),
        React.createElement("div", { className: 'notifications__category__item__text' },
            React.createElement("p", { className: 'notifications__category__item__title typography_h4' }, "\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D"),
            React.createElement("span", { className: 'typography_captionTxt' }, "\u0411\u0430\u043B\u0430\u043D\u0441 \u0438 \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D\u0438\u0435 \u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u0435"))));
};
