import "./ReviewNotification.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useShow } from "Hooks/useShow";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { fetchApi } from "Utils/fetchApi";
import { ReviewStarIcon } from "Svg/ReviewStarIcon";
import { ProductLeaveReviewDrawer } from "Components/ProductLeaveReviewDrawer/ProductLeaveReviewDrawer";
export const ReviewNotification = ({ content }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { model, image, productId, brandName, model_full, sizes } = content;
    const { onOpen: onLeaveReviewOpen, onClose: onLeaveReviewClose, open: leaveReviewOpen } = useShow();
    const [rating, setRating] = useState(0);
    const redirectToProduct = () => {
        navigate(`/products/${productId}?leaveReview=true`);
        dispatch(removeNotificaionCategory("review"));
        localStorage.setItem("lastTimeStampOfReviewNotification", new Date().toISOString().split("T")[0]);
        fetchApi({
            method: "POST",
            url: "/review/mark-notification-closed",
            body: { productId },
        });
    };
    const closeLeaveReviewDrawer = (finished) => {
        onLeaveReviewClose();
        if (!finished) {
            onReviewLeaveFinish();
            fetchApi({
                method: "POST",
                url: "/review/mark-notification-closed",
                body: { productId },
            });
        }
    };
    const onReviewLeaveFinish = () => {
        setTimeout(() => dispatch(removeNotificaionCategory("review")), 250);
        localStorage.setItem("lastTimeStampOfReviewNotification", new Date().toISOString().split("T")[0]);
    };
    return (React.createElement("div", { className: 'reviewNotification' },
        React.createElement("img", { className: 'reviewNotification__photo', src: image, alt: 'photo', onClick: redirectToProduct }),
        React.createElement("div", { className: 'reviewNotification__info' },
            React.createElement("p", { className: 'reviewNotification__info__title typography_tec_16_medium' }, "\u041A\u0430\u043A \u0432\u0430\u043C \u043F\u043E\u043A\u0443\u043F\u043A\u0430?"),
            React.createElement("p", { className: 'typography_tec_notification' }, model),
            React.createElement("div", { className: 'reviewNotification__info__stars', onMouseLeave: () => !leaveReviewOpen && setRating(0) }, [...Array(5)].map((_, index) => (React.createElement(ReviewStarIcon, { key: index, "data-active": rating >= index + 1, onClick: onLeaveReviewOpen, onMouseOver: () => setRating(index + 1) }))))),
        React.createElement(ProductLeaveReviewDrawer, { brandName: brandName, leaveReviewOpen: leaveReviewOpen, mainPhoto: image, model_full: model_full, model: model, onLeaveReviewClose: closeLeaveReviewDrawer, onReviewLeaveFinish: onReviewLeaveFinish, productId: productId, rating: rating, sizes: sizes })));
};
