import "./MobileNavigation.scss";
import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeAuthModalVisibility } from "Redux/slices/appSlice";
import { personalAreaRoutes } from "Static/personalAreaRoutes";
import { CatalogIcon } from "Svg/CatalogIcon";
import { HomeIcon } from "Svg/HomeIcon";
import { ShopingBagIcon } from "Svg/ShopingBagIcon";
import { UserIcon } from "Svg/UserIcon";
import { HeartIcon } from "Svg/HeartIcon";
import { NotificationCounter } from "Components/NotificationCounter/NotificationCounter";
import { MobileCatalog } from "Components/MobileCatalog/MobileCatalog";
export const MobileNavigation = ({ className }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { user, isUserTypeDefined } = useAppSelector((state) => state.userInfo);
    const personalAreaHandler = () => {
        if (isUserTypeDefined && user) {
            navigate("/personalArea/");
            !personalAreaRoutes.some((route) => location.pathname.startsWith(route)) &&
                localStorage.setItem("prevUrl", location.pathname + location.search);
        }
        else {
            openAuthModal();
        }
    };
    const favouritesPageHandler = () => {
        navigate("/favourites/");
        !personalAreaRoutes.some((route) => location.pathname.startsWith(route)) &&
            localStorage.setItem("prevUrl", location.pathname + location.search);
    };
    const openAuthModal = () => {
        dispatch(changeAuthModalVisibility(true));
    };
    const [isShownCatalog, setIsShownCatalog] = useState(false);
    const openCatalog = () => setIsShownCatalog(true);
    const closeCatalog = () => setIsShownCatalog(false);
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: "mobileNavigation" + (className ? ` ${className}` : "") },
            React.createElement(NavLink, { className: 'mobileNavigation__link', to: '/' },
                React.createElement(HomeIcon, { className: 'mobileNavigation__link__svg' }),
                React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u0413\u043B\u0430\u0432\u043D\u0430\u044F")),
            React.createElement("div", { className: 'mobileNavigation__link', onClick: openCatalog },
                React.createElement(CatalogIcon, { className: 'mobileNavigation__link__svg' }),
                React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u0430\u0442\u0430\u043B\u043E\u0433")),
            React.createElement(NavLink, { className: 'mobileNavigation__link', to: '/cart/' },
                React.createElement("span", { className: 'mobileNavigation__link__icon' },
                    React.createElement(ShopingBagIcon, { className: 'mobileNavigation__link__icon__svg' }),
                    React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'cart' })),
                React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u043E\u0440\u0437\u0438\u043D\u0430")),
            React.createElement("div", { className: "mobileNavigation__link" + (location.pathname === "/favourites/" ? " active" : ""), onClick: favouritesPageHandler },
                React.createElement("span", { className: 'mobileNavigation__link__icon' },
                    React.createElement(HeartIcon, { className: 'mobileNavigation__link__icon__svg mobileNavigation__link__icon__svg_favouritesPage' }),
                    React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'favourites' })),
                React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u0418\u0437\u0431\u0440\u0430\u043D\u043D\u043E\u0435")),
            React.createElement("div", { className: "mobileNavigation__link" + (location.pathname === "/personalArea/" ? " active" : ""), onClick: personalAreaHandler },
                React.createElement("span", { className: 'mobileNavigation__link__icon' },
                    React.createElement(UserIcon, { className: 'mobileNavigation__link__icon__svg' }),
                    React.createElement(NotificationCounter, { className: 'mobileNavigation__link__icon__counter', type: 'orders' })),
                React.createElement("span", { className: 'mobileNavigation__link__txt typography_captionTxt' }, "\u041A\u0430\u0431\u0438\u043D\u0435\u0442"))),
        React.createElement(MobileCatalog, { isOpen: isShownCatalog, closeHandler: closeCatalog })));
};
