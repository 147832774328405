import React from "react";
import { InfoIcon } from "Svg/InfoIcon";
export const VpnNotification = () => {
    return (React.createElement("div", { className: 'notifications__category__item' },
        React.createElement("div", { className: 'notifications__category__item__icon' },
            React.createElement(InfoIcon, null)),
        React.createElement("div", { className: 'notifications__category__item__text' },
            React.createElement("p", { className: 'notifications__category__item__title typography_h4', "data-is_warning": true }, "\u0423 \u0432\u0430\u0441 \u0432\u043A\u043B\u044E\u0447\u0435\u043D VPN!"),
            React.createElement("span", { className: 'typography_captionTxt' }, "\u0423\u0432\u0430\u0436\u0430\u0435\u043C\u044B\u0439 \u043A\u043B\u0438\u0435\u043D\u0442 - \u0432\u044B\u043A\u043B\u044E\u0447\u0438\u0442\u0435 VPN, \u0442\u043E\u0433\u0434\u0430 \u0432\u044B \u0441\u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u0432\u0438\u0434\u0435\u0442\u044C \u0431\u043E\u043B\u044C\u0448\u0435 \u0442\u043E\u0432\u0430\u0440\u043E\u0432."))));
};
