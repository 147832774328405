import "./Notifications.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { fetchApi } from "Utils/fetchApi";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { ReviewNotification } from "./components/ReviewNotification/ReviewNotification";
import { EntityPayNotification } from "./components/EntityPayNotification/EntityPayNotification";
import { DiscountNotification } from "./components/DiscountNotification/DiscountNotification";
import { VpnNotification } from "./components/VpnNotification/VpnNotification";
import { CertificatesNotification } from "./components/CertificatesNotification/CertificatesNotification";
import { OrderNotification } from "./components/OrderNotification/OrderNotification";
import { IncreasedDiscountNotification } from "./components/IncreasedDiscountNotification/IncreasedDiscountNotification";
export const Notifications = () => {
    const dispatch = useAppDispatch();
    const { notifications, isHeaderShown } = useAppSelector((state) => state.app);
    const [notificationData, setNotificationsData] = useState([]);
    useEffect(() => setNotificationsData(notifications), [notifications]);
    const closeHandler = (notification) => {
        const { category, value } = notification;
        dispatch(removeNotificaionCategory(category));
        const timeStampCategories = ["discountIncrease", "review"];
        if (timeStampCategories.includes(category)) {
            const name = category === "discountIncrease" ? "lastTimeStampOfDiscountIncrease" : "lastTimeStampOfReviewNotification";
            localStorage.setItem(name, new Date().toISOString().split("T")[0]);
        }
        if (category === "review") {
            fetchApi({
                method: "POST",
                url: "/review/mark-notification-closed",
                body: { productId: value[0].content.productId },
            });
        }
    };
    const renderNoticeContent = (category, { id, content }) => {
        switch (category) {
            case "orders":
                return React.createElement(OrderNotification, { id, content, key: id });
            case "certificates":
                return React.createElement(CertificatesNotification, { key: id });
            case "vpnWarning":
                return React.createElement(VpnNotification, { key: id });
            case "discount":
                return React.createElement(DiscountNotification, { key: id });
            case "discountIncrease":
                return React.createElement(IncreasedDiscountNotification, { content: content, key: id });
            case "entityPay":
                return React.createElement(EntityPayNotification, { id: id, key: id });
            case "review":
                return React.createElement(ReviewNotification, { content: content, key: id });
            default:
                return null;
        }
    };
    if (notificationData.length === 0)
        return null;
    return (React.createElement("div", { className: 'notifications__wrapper', "data-is_header_shown": isHeaderShown }, notificationData.map((notifictionList) => (React.createElement("div", { className: 'notifications__category', key: notifictionList.category, "data-category": notifictionList.category },
        React.createElement(CloseIcon, { className: 'notifications__category__closeIcon', isDefaultClosePosition: true, onClick: () => closeHandler(notifictionList) }),
        notifictionList.value.map((notification) => renderNoticeContent(notifictionList.category, notification)))))));
};
