import "./MobileCatalog.scss";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { changeGender } from "Redux/slices/appSlice";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { genderList } from "Static/texts";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { SwitchPanel } from "Components/SwitchPanel/SwitchPanel";
import { CustomCollapse } from "Components/CustomCollapse/CustomCollapse";
import { CrossIcon_48 } from "Svg/CrossIcon_48";
import { ChevronIcon_16 } from "Svg/ChevronIcon_16";
export const MobileCatalog = ({ isOpen, closeHandler }) => {
    const dispatch = useAppDispatch();
    const { mainMenuHeader, genderData } = useAppSelector((state) => state.app);
    const [openedCategories, setOpenedCategories] = useState([]);
    const panelHandler = (categoryId) => {
        const check = openedCategories.find((openedId) => openedId === categoryId);
        setOpenedCategories((prev) => (check ? prev.filter((openedId) => openedId !== categoryId) : [...prev, categoryId]));
    };
    const switchHandler = (genderNumber) => {
        dispatch(changeGender(genderList.find((gender) => gender.genderNumber === genderNumber)));
    };
    const linkHandler = () => {
        setOpenedCategories([]);
        closeHandler();
    };
    const nameDeclension = (name) => {
        const isMatch = !!name.match(/[а,ь]$/);
        return `${isMatch ? "Вся" : "Все"} ${name.toLocaleLowerCase()}`;
    };
    return (React.createElement(CustomDrawer, { rootClassName: 'mobileCatalog__drawer customServiceDrawer_side', placement: 'left', destroyOnClose: true, open: isOpen, onClose: closeHandler, maskElement: React.createElement(CrossIcon_48, { className: 'mobileCatalog__drawer__closeIcon', onClick: closeHandler }) },
        React.createElement("div", { className: 'mobileCatalog' },
            React.createElement(SwitchPanel, { className: 'mobileCatalog__switch', clickHandler: switchHandler, controlsData: genderList.map(({ gender, genderNumber }) => ({
                    isActive: genderData.genderNumber === genderNumber,
                    txt: gender[0].toUpperCase() + gender.slice(1),
                    type: genderNumber,
                })) }),
            React.createElement("nav", { className: 'mobileCatalog__navigation' },
                React.createElement(CustomCollapse, { activeKeys: openedCategories }, mainMenuHeader.map((category) => {
                    if (category.children.length === 0)
                        return (React.createElement(NavLink, { className: 'mobileCatalog__navigation__link typography_tec_16_menu_semiBold', to: category.url, key: category.slug, "data-is-highlight": category.highlight, onClick: closeHandler }, category.name));
                    return (React.createElement(CustomCollapse.Panel, { key: category.slug, panelKey: category.slug, forceRender: true, header: React.createElement("div", { className: 'mobileMenu__navigation__header', onClick: () => panelHandler(category.slug) },
                            React.createElement("p", { className: 'typography_tec_16_menu_semiBold' }, category.name),
                            React.createElement(ChevronIcon_16, { style: {
                                    transform: openedCategories.includes(category.slug) ? "rotate(90deg)" : "rotate(0deg)",
                                } })) },
                        React.createElement("div", { className: 'mobileMenu__navigation__list' },
                            React.createElement("div", { className: 'mobileMenu__navigation__list__link typography_tec_16_menu_semiBold' },
                                React.createElement(NavLink, { to: category.url, onClick: linkHandler }, nameDeclension(category.name)),
                                React.createElement(ChevronIcon_16, null)),
                            category.children.map((link) => (React.createElement(NavLink, { className: 'mobileMenu__navigation__list__link typography_tec_16_menu_semiBold', to: link.url, key: link.id, onClick: linkHandler }, link.name))))));
                }))))));
};
