import "./CustomToggleSwitch.scss";
import React from "react";
export const CustomToggleSwitch = ({ className, disabled, isChecked, onChangeHandler, theme = "light", }) => {
    return (React.createElement("label", { className: `customToggleSwitch__label customToggleSwitch__label_${theme}` + (className ? ` ${className}` : ""), "data-checked": isChecked, "data-theme": isChecked, onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            if (disabled)
                return;
            onChangeHandler && onChangeHandler(!isChecked);
        } },
        React.createElement("input", { className: 'customToggleSwitch__input', type: 'checkbox', defaultChecked: isChecked })));
};
