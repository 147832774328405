import "./IncreasedDiscountNotification.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { removeNotificaionCategory } from "Redux/slices/appSlice";
import { IncreasedDiscountTimer } from "Components/IncreasedDiscountTimer/IncreasedDiscountTimer";
export const IncreasedDiscountNotification = ({ content }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { user } = useAppSelector((state) => state.userInfo);
    const closeHandler = () => {
        dispatch(removeNotificaionCategory("discountIncrease"));
        localStorage.setItem("lastTimeStampOfDiscountIncrease", new Date().toISOString().split("T")[0]);
    };
    const redirectToDiscountPage = () => {
        closeHandler();
        navigate("/myDiscount/");
    };
    return (React.createElement("div", { className: 'increasedDiscountNotification' },
        React.createElement(IncreasedDiscountTimer, { discountPercent: +content.percent, expirationDate: content.dateTo, onTimerEnd: closeHandler }),
        React.createElement("div", { className: 'increasedDiscountNotification__description' },
            React.createElement("span", { className: 'typography_tec_16_default' }, `Добавили +${content.percent}% к вашей скидке`),
            user && (React.createElement("button", { className: 'typography_tec_16_default', onClick: redirectToDiscountPage }, "\u041F\u041E\u0414\u0420\u041E\u0411\u041D\u0415\u0415")))));
};
